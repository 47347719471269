import React, {Component} from 'react';
import App from './App';

import Chart from 'react-google-charts';
import {Grid, Table, TableHead, TableRow, TableCell, TableBody, Select} from '@material-ui/core';
import {width} from '@material-ui/system';

import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';

require('moment/locale/ko');

moment.locale('ko');

interface HomeState {
  data: any[];
  logs: string[];
  start: string;
  end: string;
  show: boolean[];
};

export default class extends Component<{}, HomeState> {
  state: HomeState = {
    data: [],
    logs: [],
    show: [],
    start: moment().startOf('day').format(),
    end: moment().endOf('day').format(),
  };

  componentDidMount = async () => {
    this.refresh();

    // setInterval(() => {
    // this.refresh();
    // }, 60000);
  }

  componentDidUpdate = async (pp: {}, ps: HomeState) => {
    const lambda = new App.AWS.Lambda({
      region: 'ap-northeast-2',
    });

    if (ps.start !== this.state.start || ps.end !== this.state.end) {
      this.refresh();
    }
  }

  refresh = async () => {
    const lambda = new App.AWS.Lambda({
      region: 'ap-northeast-2',
    });

    const r = await lambda.invoke({
      FunctionName: 'arn:aws:lambda:ap-northeast-2:331053433621:function:platform-utils-QueryDatabase-dev',
      Payload: JSON.stringify({
        // query: `SELECT id, aws_request_id, timestamp, function_name, error, stack FROM infra.lambda_crash_report order by "timestamp" desc limit 100`,
        // query: `SELECT function_name, stack, count(*) as "count" FROM infra.lambda_crash_report where "function_name" = '${this.state.fn}' group by "function_name", "stack" order by "count" desc limit 100`,
        query: `
            SELECT *
            FROM kb_buildit_project_jobs
            WHERE "runnable" >= '${moment(this.state.start).utc().format()}'
              AND "runnable" <= '${moment(this.state.end).utc().format()}'
            ORDER BY "project_id" DESC, "report_number" DESC, "job_id" DESC
        `,
      }),
    }).promise();

    console.log(r);

    const body = JSON.parse(r.Payload as string).body;

    const data = body;

    this.setState({
      data,
    });

    {
      const logs = await Promise.all<string>(data.map(async (v: any, i: number) => {
        if (this.state.show[i]) {
          const logs = new App.AWS.CloudWatchLogs({
            region: 'ap-northeast-2',
          });

          v.log_stream_name = (v.log_url || '').slice(127);

          if (v.log_stream_name) {
            const r = await logs.getLogEvents({
              logGroupName: '/aws/batch/job',
              logStreamName: v.log_stream_name,
              startFromHead: false,
            }).promise();

            return r.events!.map(v => moment(v.timestamp).format("L LTS") + " | " + v.message).join('\n');
          } else {
            return "";
          }
        }

        return "Loading...";
      }));

      this.setState({
        logs,
      });
    }
  }

  render = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              autoOk
              ampm={false}
              disableFuture
              format='yyyy-MM-dd HH:mm'
              value={this.state.start}
              onChange={(v) => {
                console.log(v);
                this.setState({
                  start: moment(v!).format(),
                });
              }}
            />
            <DateTimePicker
              autoOk
              ampm={false}
              disableFuture
              format='yyyy-MM-dd HH:mm'
              value={this.state.end}
              onChange={(v) => {
                console.log(v);
                this.setState({
                  end: moment(v!).format(),
                });
              }}
            />
          </MuiPickersUtilsProvider>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {/*<TableCell>스테이지</TableCell>*/}
                {/*<TableCell>프로젝트 번호</TableCell>*/}
                <TableCell>JOB ID</TableCell>
                <TableCell>JOB NAME</TableCell>
                <TableCell>JOB Q</TableCell>
                <TableCell>JOB DEF</TableCell>
                <TableCell>로그</TableCell>
                {/* <TableCell>SUBMITTED</TableCell> */}
                {/* <TableCell>PENDING</TableCell> */}
                <TableCell>RUNNABLE</TableCell>
                {/*<TableCell>STARTING</TableCell>*/}
                <TableCell>RUNNING</TableCell>
                <TableCell>FAILED</TableCell>
                <TableCell>SUCCEEDED</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((row, i) => (
                <React.Fragment>
                  <TableRow key={row.name} onClick={() => {
                    // const show = this.state.show;
                    //
                    // show[i] = !show[i];
                    //
                    // this.setState({
                    //   show,
                    // });
                    //
                    // this.refresh();
                  }}>
                    {/*<TableCell>{row.stage}</TableCell>*/}
                    {/*<TableCell>{row.project_id}</TableCell>*/}
                    <TableCell>{row.job_id}</TableCell>
                    <TableCell>{row.job_name}</TableCell>
                    <TableCell>{(row.job_queue || '').split('/')[1]}</TableCell>
                    <TableCell>{((row.job_definition || '').split('/')[1] || (row.log_url || '').split('stream=')[1] || '').split('-')[0]}</TableCell>
                    <TableCell>
                      {
                        row.log_url &&
                        <a href={row.log_url} target={"_blank"}>로그</a>
                      }
                    </TableCell>
                    {/* <TableCell>{row.submitted && moment(row.submitted).format("L LTS")}</TableCell> */}
                    {/* <TableCell>{row.pending && moment(row.pending).format("L LTS")}</TableCell> */}
                    <TableCell>{row.runnable && moment(row.runnable).format("L LTS")}</TableCell>
                    {/*<TableCell>{row.starting && moment(row.starting).format("L LTS")}</TableCell>*/}
                    <TableCell>{row.running && moment(row.running).format("L LTS")}</TableCell>
                    <TableCell>{row.failed && moment(row.failed).format("L LTS")}</TableCell>
                    <TableCell>{row.succeeded && moment(row.succeeded).format("L LTS")}</TableCell>
                  </TableRow>
                  {
                    this.state.show[i] && (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <pre style={{height: '250px', overflowY: 'scroll'}}>{this.state.logs[i]}</pre>
                        </TableCell>
                      </TableRow>
                    )
                  }
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  }
}