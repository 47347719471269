import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';
import { Grid, Card, CardActionArea, CardContent, Button, CardMedia, CardActions, Table, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel, IconButton, SwipeableDrawer, TextField, Paper, InputBase } from '@material-ui/core';

import { CognitoUserPool, CognitoUserAttribute, CognitoUser, CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';

import queryString from 'querystring';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Signin from './Signin';
import Home from './Home';

import AWS from 'aws-sdk';
import BatchJobs from './BatchJobs';
import DesignerDownload from './DesignerDownload';
import IssueCounter from "./IssueCounter";

interface AppState {
  open: boolean;
  loginChecked: boolean;
}

interface RenderProps {
  open: boolean;
  handleDrawerOpen: () => void;
}


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `100%`,
      marginLeft: drawerWidth,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }),
);

const Render = (props: RenderProps) => {
  const classes = useStyles();

  let { open, handleDrawerOpen } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Teneleven Crash Reports
          </Typography>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        open={open}
        onClose={handleDrawerOpen}
        onOpen={() => {}}
        className={classes.drawer}
      >
        <Divider />
        <List>
          <a href='/' style={{textDecoration: 'none', color: 'black'}}>
            <ListItem button key={"Home"}>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
          </a>
          <a href='/batch-jobs' style={{textDecoration: 'none', color: 'black'}}>
            <ListItem button key={"Batch Jobs"}>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary={"Batch Jobs"} />
            </ListItem>
          </a>
          <a href='/designer-download' style={{textDecoration: 'none', color: 'black'}}>
            <ListItem button key={"Designer Download"}>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary={"Designer Download"} />
            </ListItem>
          </a>
          <a href='/issue-counter' style={{textDecoration: 'none', color: 'black'}}>
            <ListItem button key={"Issue Counter"}>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary={"Issue Counter"} />
            </ListItem>
          </a>
        </List>
      </SwipeableDrawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Router>
          <div className={classes.root}>
            <Switch>
              <Route path='/signin'>
                <Signin />
              </Route>
              <Route path='/batch-jobs'>
                <BatchJobs />
              </Route>
              <Route path='/designer-download'>
                <DesignerDownload />
              </Route>
              <Route path='/issue-counter'>
                <IssueCounter />
              </Route>
              <Route path='/'>
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </main>
    </div>
  );
}

export default class App extends Component<{}, AppState> {
  static AWS: typeof AWS;
  static session: any;

  state: AppState = {
    loginChecked: false,
    open: false,
  };

  componentDidMount = async () => {
    if (window.location.pathname !== '/signin') {
      const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID!,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
      };
  
      const userPool = new CognitoUserPool(poolData);
  
      const cognitoUser = userPool.getCurrentUser();
  
      if (!cognitoUser) {
        window.location.href = `${process.env.REACT_APP_COGNITO_DOMAIN}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=https://${window.location.host}/signin&response_type=code`;
      } else {
        cognitoUser.getSession((e: Error, session: CognitoUserSession) => {
          AWS.config.region = 'us-east-1';
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
            Logins: {
              [`cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: session.getIdToken().getJwtToken(),
            },
          })
  
          App.AWS = AWS;
          App.session = session.getIdToken().payload;
  
          this.setState({
            loginChecked: true,
          });
        });
      }
    } else {
      this.setState({
        loginChecked: true,
      });
    }
  }

  render = () => {
    if (this.state.loginChecked) {
      return (
        <Render {...this.state} handleDrawerOpen={() => {
          this.setState({
            open: !this.state.open,
          });
        }} />
      )
    } else {
      return <div>Loading...</div>
    }
  }
} 
