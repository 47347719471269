import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import App from "./App";

const COUNTERS = [
  'BUILDIT',
  'PUBDATA',
];

function Counter({name, setInfo}: any) {
  async function onClick() {
    const ddb = new App.AWS.DynamoDB.DocumentClient({
      region: 'ap-northeast-2',
    });

    const r = await ddb.update({
      TableName: 'teneleven-admin-issue-counter',
      Key: {'id': name},
      ExpressionAttributeNames: {'#counter': 'counter'},
      ExpressionAttributeValues: {':counter': 1},
      UpdateExpression: 'SET #counter = #counter + :counter',
      ReturnValues: 'UPDATED_OLD',
    }).promise();

    const {counter} = r.Attributes!;

    const id = `${name}-${counter}`;

    await navigator.clipboard.writeText(id);
    setInfo(`새 티켓 번호가 클립보드에 복사되었습니다: ${id}`);
  }

  return (
    <Button onClick={onClick} style={{"margin": "10px"}} variant={"contained"}>{name}</Button>
  )
}

export default function (props: any) {
  const [info, setInfo] = useState("발급할 티켓 이름을 눌러주세요");

  return (
    <div>
      <div>
        {info}
      </div>
      <div>
        {
          COUNTERS.map(x => {
            return <Counter setInfo={setInfo} name={x}/>;
          })
        }
      </div>
    </div>
  );
}
