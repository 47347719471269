import React, { Component } from 'react';
import App from './App';

import Chart from 'react-google-charts';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Select } from '@material-ui/core';
import { width } from '@material-ui/system';

import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker ,
} from '@material-ui/pickers';

require('moment/locale/ko');

moment.locale('ko');

interface HomeState {
  fns: any[];
  data: any[];
  fn?: string;
  start: string;
  end: string;
};

export default class extends Component<{}, HomeState> {
  state: HomeState = {
    fns: [],
    data: [],
    start: moment().startOf('day').format(),
    end: moment().endOf('day').format(),
  };

  componentDidMount = async () => {
    this.refresh();

    setInterval(() => {
      this.refresh();
    }, 60000);
  }

  componentDidUpdate = async (pp: {}, ps: HomeState) => {
    const lambda = new App.AWS.Lambda({
      region: 'ap-northeast-2',
    });

    if (this.state.fn && (ps.fn !== this.state.fn || ps.start !== this.state.start || ps.end !== this.state.end)) {
      this.refresh();
    }
  }

  refresh = async () => {
    const lambda = new App.AWS.Lambda({
      region: 'ap-northeast-2',
    });

    {
      const r = await lambda.invoke({
        FunctionName: 'arn:aws:lambda:ap-northeast-2:331053433621:function:platform-utils-QueryDatabase-dev',
        Payload: JSON.stringify({
          query: `
            SELECT *
            FROM
            (
              SELECT distinct function_name
              FROM kb_lambda_crash_report
            ) a
            LEFT OUTER JOIN
            (
              SELECT function_name, COUNT(*) AS "count"
              FROM kb_lambda_crash_report
              WHERE "timestamp" >= '${moment(this.state.start).utc().format()}' AND "timestamp" <= '${moment(this.state.end).utc().format()}'
              GROUP BY function_name
            ) b
            USING (function_name)
            ORDER BY "function_name" asc
          `,
        }),
      }).promise();
      
      const fns = JSON.parse(r.Payload as string).body.filter((x: any) => x.count > 0);

      this.setState({
        fns,
        fn: this.state.fn || fns[0].function_name,
      });
    }

    if (this.state.fn) {
      const r = await lambda.invoke({
        FunctionName: 'arn:aws:lambda:ap-northeast-2:331053433621:function:platform-utils-QueryDatabase-dev',
        Payload: JSON.stringify({
          // query: `SELECT id, aws_request_id, timestamp, function_name, error, stack FROM kb_lambda_crash_report order by "timestamp" desc limit 100`,
          // query: `SELECT function_name, stack, count(*) as "count" FROM kb_lambda_crash_report where "function_name" = '${this.state.fn}' group by "function_name", "stack" order by "count" desc limit 100`,
          query: `
            SELECT
              *
            FROM
            (
              SELECT
                MAX(timestamp) as "timestamp",
                stack,
                COUNT(*) as "count"
              FROM kb_lambda_crash_report 
              WHERE "function_name" = '${this.state.fn}'
                AND "timestamp" >= '${moment(this.state.start).utc().format()}' AND "timestamp" <= '${moment(this.state.end).utc().format()}'
              GROUP BY "stack"
              ORDER BY timestamp DESC
              limit 100
            ) a
            JOIN
            (
              SELECT
                *
              FROM
              (
                SELECT
                *,
                ROW_NUMBER() OVER (PARTITION BY "stack" ORDER BY "timestamp" DESC) as "row_number"
                FROM kb_lambda_crash_report 
              ) c
              WHERE "row_number" = 1
            ) b
            USING ("stack")
            ORDER BY a."timestamp" DESC
            `,
        }),
      }).promise();

      const body = JSON.parse(r.Payload as string).body;
  
      const data = body;
  
      this.setState({
        data,
      });
    }
  }

  render = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Select
            native
            onChange={(e) => {
              this.setState({
                fn: e.target.value as string,
              });
            }}
          >
            {
              this.state.fns.map(v => {
                return <option value={v.function_name}>{v.function_name} ({v.count})</option>
              })
            }
          </Select>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              autoOk
              ampm={false}
              disableFuture
              format='yyyy-MM-dd HH:mm'
              value={this.state.start}
              onChange={(v) => {
                console.log(v);
                this.setState({
                  start: moment(v!).format(),
                });
              }}
            />
            <DateTimePicker
              autoOk
              ampm={false}
              disableFuture
              format='yyyy-MM-dd HH:mm'
              value={this.state.end}
              onChange={(v) => {
                console.log(v);
                this.setState({
                  end: moment(v!).format(),
                });
              }}
            />
          </MuiPickersUtilsProvider>
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>최근 발생 시간</TableCell>
              <TableCell>최근 발생 이벤트</TableCell>
              <TableCell>최근 발생 메타데이터</TableCell>
              <TableCell>발생 횟수</TableCell>
              <TableCell>스택 트레이스</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data.map(row => (
              <TableRow key={row.name}>
                <TableCell>{moment(row.timestamp).format("L LTS")}</TableCell>
                <TableCell><pre>{JSON.stringify(JSON.parse(row.event), null, 2)}</pre></TableCell>
                <TableCell><pre>{JSON.stringify(JSON.parse(row.metadata), null, 2)}</pre></TableCell>
                <TableCell>{row.count}</TableCell>
                <TableCell><pre>{row.stack}</pre></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
      </Grid>
    )
  }
}