import React, { Component } from 'react';
import App from './App';

import Chart from 'react-google-charts';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Select } from '@material-ui/core';
import { width } from '@material-ui/system';

import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker ,
} from '@material-ui/pickers';

import _ from 'lodash';

require('moment/locale/ko');

moment.locale('ko');
interface HomeState {
  data: any[];
};

export default class extends Component<{}, HomeState> {
  state: HomeState = {
    data: [],
  };

  componentDidMount = async () => {
    this.refresh();
  }

  componentDidUpdate = async (pp: {}, ps: HomeState) => {
  }

  refresh = async () => {
    const s3 = new App.AWS.S3({
      region: 'ap-northeast-2',
    });

    const list = await new Promise(async (resolve, reject) => {
      const list = [] as AWS.S3.Object[];

      s3.listObjectsV2({
        Bucket: 'teneleven-application-registry-apn2',
        Prefix: 'buildit-designer/',
      // @ts-ignore
      }).eachPage(async (e, r, done) => {
        if (e) {
          reject(e);
        } else if (!r || !r.Contents) {
          resolve(list);
        } else {
          list.push(...r.Contents!.filter(x => x.Key!.endsWith('.zip') || x.Key!.endsWith('.exe')));
          
          done!();
        }
      });
    }) as AWS.S3.Object[];

    let data = await Promise.all(list.map(async x => ({
      name: x.Key!.slice(17),
      date: moment(x.LastModified!).format('YYYY-MM-DD HH:mm:ss'),
      link: await new Promise((resolve) => {
        s3.getSignedUrl('getObject', {
          Bucket: 'teneleven-application-registry-apn2',
          Key: x.Key!,
          Expires: 43200,
        }, (e, r) => {
          resolve(r);
        })
      }),
    })));

    data = _.sortBy(data, x => x.date).reverse();

    this.setState({
      data,
    });
  }

  render = () => {
    return (
      <Grid container>
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>수정날짜</TableCell>
              <TableCell>다운로드</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data.map((row, i) => (
              <React.Fragment>
                <TableRow key={row.name} onClick={() => {
                }}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell><a href={row.link}>다운로드</a></TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Grid>
    )
  }
}